<template>
  <!-- 订单取消 -->
  <div style="background-color: #f6f6f6; height: 100%">
    <van-nav-bar :title="topType==1?'退款原因':'取消原因'" left-arrow @click-left="$router.back()" fixed placeholder>
      <template #right>
        <span @click="sunmit">提交</span>
      </template>
    </van-nav-bar>

    <div class="cancel-box">
      <div>
        <img style="width: 2.5rem;height: 2.5rem;" :src="require('/src/assets/对勾小@2x.png')" alt="">
      </div>
      <div style="font-size: 20px; font-weight: 600; color: #333333">
        取消订单
      </div>
    </div>

    <div class="box">
      <div style="padding: 10px 16px; font-weight: 600; color: #333333">
        请选择取消订单原因,帮助我们改进
      </div>

      <!-- 列表 -->
      <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell v-for="(item,index) in list" :key="index" :title="item.title" clickable @click="select(index)">
            <template #right-icon>
              <van-radio :name="index" checked-color="#ff9b2d" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
  </div>
</template>

<script>
  import { twominutescancelorder, cancelorders } from '/src/api/takeOut/cancelReason/index.js'
  import { Toast } from "vant";
  export default {
    name: "cancelReason",
    components: {},
    data() {
      return {
        radio: 0,// 列表选择
        orderId: 0,// 订单号
        // 列表内容
        currentConten: '不想要了/临时有事',
        // 列表
        list: [
          { title: '不想要了/临时有事' },
          { title: '点错了/点多了/点少了' },
          { title: '地址/电话填错了' },
          { title: '忘记用红包/优惠券' },
          { title: '忘记写备注' },
          { title: '商家联系我取消' },
          { title: '骑手联系我取消' },
          { title: '其他原因' },
        ],
        topType: '',//导航栏文字状态
        currentData: {
          userRemark: '',//下单备注
          acceptAddress: '',//收获地址
          acceptPhone: '',//下单人号码
          pricePackage: '',//打包费
          priceTransportUser: '',//配送费
          priceCouponReduce: '',//优惠券减少费用
          payFee: '',//支付费用（合计）
          payFeeReduce: '',//优惠费用
          acceptName: '',//下单人姓名
          id: '',//订单id
          createTime: '',//下单时间
          payType: '',//支付方式3支付宝 4微信
          couponReduceId: '',//优惠券id
          allGoods: {// 订单商品
            id: '',// 订单商品id
            createTime: '',// 创建时间
            orderId: '',// 所属订单id
            goodsId: '',// 订单商品id
            goodsName: '',// 商品名
            goodsNum: '',// 订单商品数量
            goodsPrice: '',// 商品购买时单价
            packingPrice: '',// 打包费
            goodsTotalPrice: '',// 商品价格（加上规格之后的*数量的价格）
            goodsStandardIds: '',// 商品规格 规格id:选项id_规格id:选项id
            goodsStandardNames: '',// 商品规格名字 用于显示 口味:微辣_分量:1人份
            goodsUrl: ['http://121.36.73.162:9000/joolun/1/material/12ff527e-b4b3-4284-963d-05d8b90e965e.jpg'],// 商品图片
          }
        },
      };
    },
    created() {
      this.topType = this.$route.params.type
      this.currentData = this.$route.params.obj
      this.orderId = this.$route.params.id
    },
    methods: {
      // 列表选择
      select(index) {
        this.radio = index
        this.currentConten = this.list[index].title
      },
      // 提交
      sunmit() {
        // 取消订单(超时退款)
        if (this.type == 3) {
          let param = {
            id: this.currentData.id,
            couponId: this.currentData.couponReduceId ? this.currentData.couponReduceId : '',//优惠券id,
          }
          cancelorders(param).then(res => {
            console.log(res)
            if (res.data.code == 0) {
              Toast("取消成功");
              this.$router.push({ path: '/takeOutOrderDetail', query: { id: this.orderId } })
            }
          })
        } else {// 退款
          let param = {
            id: this.currentData.id,// 订单id
            cancelReason: this.currentConten,// 取消原因
            delFlag: 0,//0-第一次取消 1-确认取消
          }
          twominutescancelorder(param).then(res => {
            console.log(res)
            console.log('退款')
            let msg = ''
            if (res.data.code == 0) {
              switch (String(res.data.data.flag)) {
                case '0':
                  msg = '退款成功';
                  break;
                case '1':
                  msg = '提交退款申请，待商家确认?';
                  break;
                case '2':
                  msg = '您的订单已经在配送中,真的不再等等了吗?';
                  break;
                case '3':
                  msg = "超时退款可能将会被商家驳回是否继续退款?";
                  break;
              }
              console.log(msg)
              if (Number(res.data.data.flag) != 0) {
                this.$dialog
                  .confirm({
                    title: "提示",
                    message: msg,
                    confirmButtonText: "取消",
                    confirmButtonColor: "#576B95",
                    cancelButtonText: "仍然退款",
                  })
                  .then(() => {
                    console.log('取消')
                  })
                  .catch(() => {
                    // 确认退款
                    let param = {
                      id: this.currentData.id,// 订单id
                      cancelReason: this.currentConten,// 取消原因
                      delFlag: 1,//0-第一次取消 1-确认取消
                    }
                    twominutescancelorder(param).then(res => {
                      console.log(res)
                      if (res.data.code == 0) {
                        Toast('提交成功');
                        this.$router.push({ path: '/takeOutOrderDetail', query: { id: this.orderId } })
                      } else {
                        if (res.data.msg) {
                          Toast(res.data.msg);
                        }
                      }
                    })
                  });
              } else {
                let param = {
                  id: this.currentData.id,// 订单id
                  cancelReason: this.currentConten,// 取消原因
                  delFlag: 1,//0-第一次取消 1-确认取消
                }
                twominutescancelorder(param).then(res => {
                  console.log(res)
                  if (res.data.code == 0) {
                    Toast('提交成功');
                    this.$router.push({ path: '/takeOutOrderDetail', query: { id: this.orderId } })
                  } else {
                    if (res.data.msg) {
                      Toast(res.data.msg);
                    }
                  }
                })
              }
              // 清除选中
              this.result = []
            } else {
              Toast(res.data.msg);
            }
          })
        }
      }
    },
  };
</script>

<style lang="less" scoped>
  .cancel-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0 10px;

    .success-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      background-color: #6dd400;
      color: #fff;
      font-size: 28px;
      border-radius: 50%;
      margin-bottom: 20px;
    }
  }

  .box {
    margin: 0 5px 10px;
    // padding: 11px;
    background-color: #fff;
    border-radius: 10px;
  }
</style>